// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-es-js": () => import("./../../../src/pages/about-us.es.js" /* webpackChunkName: "component---src-pages-about-us-es-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-pt-js": () => import("./../../../src/pages/about-us.pt.js" /* webpackChunkName: "component---src-pages-about-us-pt-js" */),
  "component---src-pages-blog-en-index-js": () => import("./../../../src/pages/blog/en/index.js" /* webpackChunkName: "component---src-pages-blog-en-index-js" */),
  "component---src-pages-blog-es-index-js": () => import("./../../../src/pages/blog/es/index.js" /* webpackChunkName: "component---src-pages-blog-es-index-js" */),
  "component---src-pages-blog-pt-index-js": () => import("./../../../src/pages/blog/pt/index.js" /* webpackChunkName: "component---src-pages-blog-pt-index-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-pt-js": () => import("./../../../src/pages/contact.pt.js" /* webpackChunkName: "component---src-pages-contact-pt-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-our-services-en-js": () => import("./../../../src/pages/our-services.en.js" /* webpackChunkName: "component---src-pages-our-services-en-js" */),
  "component---src-pages-our-services-es-js": () => import("./../../../src/pages/our-services.es.js" /* webpackChunkName: "component---src-pages-our-services-es-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-pt-js": () => import("./../../../src/pages/our-services.pt.js" /* webpackChunkName: "component---src-pages-our-services-pt-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

